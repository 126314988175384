@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

/* Reset margins and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
  background-color: #f8f9fa;
  color: #333;
}

/* Hero banner image styling */
#hero_banner_img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  z-index: -99;
}

/* Overlay to darken the background image */
.overlay {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
  background: rgba(0, 0, 0, 0.39);
}

/* Logo styling */
.logo {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  font-size: 15.5px;
  position: absolute;
  z-index: 10;
  color: #1d2549;
  background: #fe961e;
  padding: 20px 10px 50px 10px;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  left: 5%;
  transition: 0.5s;
}

/* .logo:hover {
  padding-top: 100px;
} */

/* Hero banner content styling */
.hero_banner_content {
  font-size: 39.4px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin-left: 5%;
}

/* Dash below the hero banner content */
.hero_banner_content::after {
  content: '';
  display: block;
  width: 40%;
  height: 10px;
  background-color: #fabb06;
  margin-top: 10px;
  position: relative;
  margin: auto;
}

/* Landing content positioning */
.landing_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-left: 5%;
}

.landing_flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* Button styling for "Consult Now" */
.consult {
  background-color: #2f313f;
  color: #fe961e;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-transform: uppercase;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

/* Hover effect for the button */
.consult:hover {
  background-color: #2f313f;
  transform: translateY(-3px);
}

/* Active effect for the button */
.consult:active {
  background-color: #2f313f;
  transform: translateY(0);
}

.nav {
  position: absolute;
  bottom: 10px;
  right: 20px;
  background: #2f313f;
  padding: 1.4% 0.6%;
  border-radius: 10px;
}

ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 40vw;
  right: 0;
}

li {
  list-style: none;
}

.nav-links {
  text-decoration: none;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 18px;
}

.about {
  background: #2f313f;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 3%;
}

.section_head {
  font-size: 70px;
  color: #fabb06;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  /* writing-mode: vertical-rl; */
  /* text-orientation: mixed; */
  /* text-decoration: underline; */
  /* text-decoration-color: #fff; */
  /* transform: rotate(180deg); */
  margin: 0 auto;
}


.about_content {
  font-family: "Lato", sans-serif;
  font-size: 20px;
  color: #fff;
  text-align: justify;
  margin: 0 auto;
  width: 75%;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Services Section */
.services {
  text-align: center;
  padding: 50px 0;
}

.services h2 {
  font-size: 42px;
  margin-bottom: 50px;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
}

.services h2::after {
  content: '';
  width: 80px;
  height: 4px;
  background-color: #fabb06;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.service {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.service:hover {
  transform: translateY(-10px);
}

.service img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.service-content {
  padding: 20px;
  text-align: left;
}

.service-content h3 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #1d2549;
}

.service-content p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

.service-content a {
  display: inline-block;
  margin-top: 20px;
  color: #ff6f61;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
}

.service-content a:hover {
  color: #e65b50;
}

/* Pricing Section */
.pricing {
  background-color: #fff;
  padding: 60px 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.pricing h2 {
  font-size: 42px;
  color: #1d2549;
  text-transform: uppercase;
  margin-bottom: 30px;
  text-align: center;
}

.pricing-content {
  font-size: 18px;
  color: #555;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

/* Industries Section */
.industries {
  background-color: #f4f4f4;
  padding: 60px 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.industries h2 {
  font-size: 42px;
  color: #1d2549;
  text-transform: uppercase;
  margin-bottom: 30px;
  text-align: center;
}

.industries p {
  font-size: 18px;
  color: #555;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

/* Blog Section */
.blog {
  background-color: #fff;
  padding: 60px 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.blog h2 {
  font-size: 42px;
  color: #1d2549;
  text-transform: uppercase;
  margin-bottom: 30px;
  text-align: center;
}

.blog p {
  font-size: 18px;
  color: #555;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

/* Contact Us Section */
.contact {
  background-color: #f4f4f4;
  padding: 60px 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}

.contact img {
  width: 60%;
  margin: auto;
  border-radius: 10%;
}

.contact h2 {
  font-size: 42px;
  color: #1d2549;
  text-transform: uppercase;
  margin-bottom: 30px;
  text-align: center;
}

.contact p {
  font-size: 18px;
  color: #555;
  line-height: 1.8;
  max-width: 600px;
  margin: 0 auto;
  text-align: justify;
}

.contact-form {
  max-width: 600px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form label {
  font-size: 16px;
  color: #333;
}

.contact-form input {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease;
}

.contact-form input:focus {
  border-color: #fabb06;
}

.submit-btn {
  background-color: #1d2549;
  color: #fff;
  padding: 15px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-btn:hover {
  background-color: #fabb06;
  color: #1d2549;
}

/* Terms of Policy and Services Section */
.terms {
  background-color: #fff;
  padding: 60px 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}

.terms img {
  width: 60%;
  margin: auto;
  clip-path: polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%);
}

.terms h2 {
  font-size: 42px;
  color: #1d2549;
  text-transform: uppercase;
  margin-bottom: 30px;
  text-align: center;
}

.terms p {
  font-size: 18px;
  color: #555;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
}

/* Meet the Founder Section */
.founder {
  background-color: #f4f4f4;
  padding: 60px 20px;
  padding-right: 0;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}

.founder h2 {
  font-size: 42px;
  color: #1d2549;
  text-transform: uppercase;
  margin-bottom: 30px;
  text-align: center;
}

.founder p {
  font-size: 18px;
  color: #555;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.founder_img {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  /* background-color: #2f313f; */
}

.founder img {
  width: 60%;
  margin: auto;
}

/* Footer Styling */
.footer {
  background-color: #2f313f;
  color: #fff;
  padding: 40px 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.footer-logo img {
  width: 150px;
}

.footer-links {
  margin: 20px 0;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 15px;
}

.footer-links a {
  color: #fabb06;
  text-decoration: none;
  font-size: 16px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-contact p {
  margin: 5px 0;
}

.footer-social {
  margin: 20px 0;
}

.footer-social a {
  color: #fff;
  margin: 0 10px;
  font-size: 24px;
  text-decoration: none;
}

.footer-social a:hover {
  color: #fabb06;
}

.footer-bottom {
  border-top: 1px solid #444;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
}

#bg_video{
  display: none;
}
/* Hamburger menu styling */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 1000;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #1d2549;
  margin: 4px 0;
  transition: 0.4s;
}

/* Open state of the hamburger menu */
.hamburger.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Navigation menu styling */
.nav-menu {
  display: none;
}

.nav-menu.open {
  display: flex;
  flex-direction: column;
  /* position: absolute; */
  top: 60px;
  right: 0;
  width: 100%;
  padding: 20px;
  z-index: 999;
}

.nav-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-menu li {
  margin: 10px 0;
}

.nav-menu a {
  text-decoration: none;
  color: #fff;
  padding: 10px;
  display: block;
  border-radius: 5px;
  text-align: center;
}

.nav-menu a:hover {
  background-color: #555;
}
.nav-menu{
  background: none;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .container {
    padding: 15px;
  }

  .hero_banner_content {
    font-size: 32px;
    margin-left: 2%;
  }

  .landing_content {
    margin-left: 2%;
  }
}

@media (max-width: 992px) {
  .about {
    grid-template-columns: 1fr;
  }

  .founder {
    grid-template-columns: 1fr;
  }

  .contact {
    grid-template-columns: 1fr;
  }

  .terms {
    grid-template-columns: 1fr;
  }

  .service-grid {
    grid-template-columns: 1fr;
  }

  .service-content h3 {
    font-size: 20px;
  }

  .service-content p {
    font-size: 14px;
  }

  .pricing-content {
    font-size: 16px;
  }

  .blog p {
    font-size: 16px;
  }

  .industries p {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .section_head {
    font-size: 48px;
  }

  .footer-content {
    flex-direction: column;
  }

  .footer-links ul {
    flex-direction: column;
  }

  .footer-social a {
    font-size: 20px;
  }

  .contact-form {
    padding: 15px;
  }

  .consult {
    padding: 10px 20px;
    font-size: 14px;
  }

  .contact img {
    width: 80%;
  }

  .founder_img {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .logo{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    border-bottom-left-radius: 10%;
    border-bottom-right-radius: 10%;
    transition: 0.5s;
    /* top: -30%; */
  }
  .about{
    padding: 1%;
  }
  .about_content {
    font-size: 18px;
    width: 90%;
  }
  /* .logo:hover{
    top:0;
  } */
  .hamburger {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1em;
  }
  
  .nav-menu ul {
    display: flex;
    flex-direction: column;
    /* min-height: 100vh;
    max-height: 100vh; */
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .nav-links {
    background: none !important;
  }
  .hero_banner{
    min-height: 100vh;
  }
  #hero_banner_img{
    display: none;
  }
  nav{
    display: none;
  }
  .hero_banner_content {
    font-size: 24px;
    margin: auto;
  }
  .landing_content{
    display: flex;
    flex-direction: column;
  }

  .logo {
    margin: 0 auto;
    left: 25%;
    font-size: 14px;
    padding: 10px 5px 30px 5px;
  }
  #bg_video {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    background-size: cover;
    overflow: hidden;
  }
    
  .hero_banner_content {
    text-shadow: 2px 2px #000;
  }
  .hero_banner_content::after {
    height: 6px;
  }
  .section_head {
    font-size: 36px;
  }

  .contact-form input {
    font-size: 14px;
  }

  .submit-btn {
    font-size: 16px;
  }

  .footer-logo img {
    width: 120px;
  }
}
